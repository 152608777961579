// Parallax
import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import { CustomLandingBrandProvider } from "./src/context/customLandingBrand"

export const wrapPageElement = ({ element, props }) => {
  return (
    <ParallaxProvider>
      <CustomLandingBrandProvider>{element}</CustomLandingBrandProvider>
    </ParallaxProvider>
  )
}

export const shouldUpdateScroll = ({ routerProps }) => {
  // if going through link navigation, scroll to top on middle of the animation
  if (routerProps?.location?.action) {
    window.setTimeout(() => window.scrollTo(0, 0), 350)
    return false
  }
  // if going through history navigation, go directly to the prev scroll position
  return true
}

// Remember to duplicate changes (if applies) to gatsby-ssr too
