import React from "react"

export const CustomLandingBrandContext = React.createContext()

export const CustomLandingBrandProvider = ({ children }) => {
  const [customLandingBrand, setcustomLandingBrand] = React.useState(undefined)

  return (
    <div>
      <CustomLandingBrandContext.Provider
        value={{ customLandingBrand, setcustomLandingBrand }}
      >
        {children}
      </CustomLandingBrandContext.Provider>
    </div>
  )
}
