// main imports
import * as React from "react"

// plugin imports

// style imports
import "./marquee.scss"

// components imports

// svg imports

export default function Marquee({ list, className = "" }) {
  const content = []
  var charCount = 0

  for (let i = 0; i < list.length; i++) {
    charCount = charCount + list[i].length
    content.push(<span key={i}>{list[i]} </span>)
  }

  // console.log(list, charCount)

  return (
    <div className={`marquee mondwest ${className}`}>
      <div className="marquee__inner" aria-hidden="true">
        {content}
        {content}
        {content}
        {content}
        {charCount < 48 && (
          <>
            {content}
            {charCount < 40 && (
              <>
                {content}
                {list.length < 34 && (
                  <>
                    {content}
                    {list.length < 30 && (
                      <>
                        {content}
                        {list.length < 24 && (
                          <>
                            {content}
                            {content}
                            {list.length < 20 && (
                              <>
                                {content}
                                {content}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
