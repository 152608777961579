// main imports
import * as React from "react"

// plugin imports
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next"

// components imports
import VimeoVideo from "../video/vimeoVideo"

// style imports
import "./footer.scss"

// svg imports
import AnalogClockCustom from "../analogClock/analogClock"

// video imports
// import videoContact from "../../videos/contactMerkato.mp4"
import Marquee from "../marquee/marquee"

// component
export default function Footer({ contactPage, landingURL }) {
  const { t, changeLanguage, language } = useI18next()

  const menuItems = [
    { link: landingURL, text: t("Inicio") },
    { link: "/works", text: t("Trabajos") },
    { link: "/weare", text: t("Somos") },
    // { link: "/method", text: t("Método") },
    { link: "/contact", text: t("Contacto") },
  ]

  const RRSSItems = [
    { link: "https://www.instagram.com/venturexperience/", text: "Instagram" },
    {
      link: "https://www.linkedin.com/company/venturexperience",
      text: "LinkedIn",
    },
  ]

  const legalItems = [
    { link: "/legal/notice", text: t("Aviso legal") },
    { link: "/legal/privacy", text: t("Política de privacidad") },
    { link: "/legal/cookies", text: t("Política de cookies") },
  ]

  return (
    <>
      <footer id="footer" className={`${contactPage ? "contactFooter" : ""}`}>
        {contactPage && (
          <div className="footerBg">
            {/* <Video src={videoContact} background /> */}
            <VimeoVideo videoId={846943064} background />
            <div className="videoBlackOverlay" />
          </div>
        )}

        <Marquee
          className="footerMarquee"
          list={[t("Contacta con nosotros y explícanos qué cambio necesitas.")]}
        />

        <div className="footerContents">
          <div>
            <div className="clocks">
              <div>
                <AnalogClockCustom timeZone="Europe/Madrid" />
                <p>
                  <Trans>España</Trans>
                  <br />
                  {/* <a
                  href="https://goo.gl/maps/ZbmjXmokjhg556ha9"
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
                  Mestre Racional, 11 <br />
                  46005 Valencia
                  {/* </a> */}
                  <br />
                  <a href="tel:0034963122200">+34 963 122 200</a>
                </p>
              </div>
              <div>
                <AnalogClockCustom timeZone="Mexico/General" />
                <p>
                  Mexico
                  <br />
                  {/* <a
                  href="https://goo.gl/maps/2KXirtU58Bk3qZQXA"
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
                  La Ley, 3048 <br />
                  44680 Guadalajara
                  {/* </a> */}
                  <br />
                  <a href="00523336767383">+52 33 3676 7383</a>
                </p>
              </div>
            </div>
            <a href="mailto:hola@venturexperience.com" className="mail">
              hola@venturexperience.com
            </a>
          </div>

          <div className="footerLinks">
            <div className="footerMenu">
              {menuItems.map(item => (
                <div className="menuIt" key={item.text}>
                  <Link to={item.link}>{item.text}</Link>
                </div>
              ))}
              {RRSSItems.map(item => (
                <div className="menuIt" key={item.text}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.text}
                  </a>
                </div>
              ))}
              {language === "en" ? (
                <button
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    changeLanguage("es")
                  }}
                >
                  <span>ESP</span>
                </button>
              ) : (
                <button
                  onClick={e => {
                    e.preventDefault()
                    changeLanguage("en")
                  }}
                >
                  <span>ENG</span>
                </button>
              )}
            </div>
            <div className="footerCredits">
              <div className="footerMenu">
                {legalItems.map(item => (
                  <div className="menuIt" key={item.text}>
                    <Link to={item.link}>{item.text}</Link>
                  </div>
                ))}
              </div>

              <div>
                <Link to={landingURL}>VENTURE</Link>® {new Date().getFullYear()}
                <br />
                Web by <a href="https://www.peim.es">Peim! Estudio</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
