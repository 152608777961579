import React from "react"

import "./loader.scss"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Marquee from "../marquee/marquee"

export default function Loader({ loaded }) {
  const { t } = useTranslation()

  return (
    <div className={`loader ${loaded ? "loaded" : ""}`}>
      <div className="mondwest">
        <Marquee list={[t("loading")]} />
      </div>
    </div>
  )
}
