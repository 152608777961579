// main imports
import * as React from "react"

// plugin imports

// style imports
import "./navbar.scss"
import { Link } from "gatsby-plugin-react-i18next"

// svg imports
import Logo from "../../svg/logo.svg"
import MenuToggle from "./menuToggle"

// components imports

export default function Navbar({
  topOfPage,
  menuShow,
  setMenuShow,
  landingURL,
  closeMenu,
}) {
  return (
    <>
      <div
        className={`navbar navbarUnder ${
          topOfPage ? "" : "navbarSmall navbarBg"
        }`}
      >
        <div className={`navLogo ${topOfPage | menuShow ? "" : "logoSmall"}`}>
          <Logo />
        </div>
      </div>
      <header className={`navbar ${topOfPage | menuShow ? "" : "navbarSmall"}`}>
        <div className={`navLogo ${topOfPage | menuShow ? "" : "logoSmall"}`}>
          <Link to={landingURL} onClick={closeMenu}>
            <Logo />
          </Link>
        </div>

        <MenuToggle
          menuShow={menuShow}
          setMenuShow={setMenuShow}
          landingURL={landingURL}
        />
      </header>
    </>
  )
}
