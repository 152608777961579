import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

import "./transitions.scss"

export default function Transition({ children, location }) {
  return (
    <TransitionGroup>
      <ReactTransition
        key={location?.pathname || ""}
        timeout={{
          enter: 250,
          exit: 250,
        }}
      >
        {state => <div className={state}>{children}</div>}
      </ReactTransition>
    </TransitionGroup>
  )
}
