// main imports
import * as React from "react"

// plugin imports

// style imports
import "./menuToggle.scss"

// svg imports

// components imports

export default function MenuToggle({ menuShow, setMenuShow }) {
  const [hoverClass, setHoverClass] = React.useState(false)

  const handleClick = () => {
    setMenuShow(!menuShow)
  }

  React.useEffect(() => {
    if (!menuShow) {
      setHoverClass(false)
      return
    }
    let timeout = setTimeout(() => setHoverClass(true), 800)
    return () => clearTimeout(timeout)
  }, [menuShow])

  React.useEffect(() => {
    if (typeof document !== `undefined`) {
      document.body.classList.remove("menuShow")
    }
  }, [])

  return (
    <button
      id="menuToggle"
      onClick={handleClick}
      className={`
        ${menuShow ? "menuShow" : ""} 
        ${hoverClass ? "hoverAnim" : ""}`}
    >
      <span></span>
      <span></span>
    </button>
  )
}
