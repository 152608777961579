// main imports
import * as React from "react"

// plugin imports
import Transition from "./transitions"

// style imports
import "../../styles/main.scss"
import "./layout.scss"

// context imports
import { CustomLandingBrandContext } from "../../context/customLandingBrand"

// svg imports
import Menu from "./menu"
import Footer from "./footer"
import Marquee from "../marquee/marquee"
import CookieBanner from "../cookiesBanner/CookiesBanner"
import Navbar from "./navbar"

// components imports

// component
export default function Layout({ location, children }) {
  const windowGlobal = typeof window !== "undefined" && window
  const documentGlobal = typeof document !== "undefined" && document

  const [menuShow, setMenuShow] = React.useState(false)
  const [topOfPage, setTopOfPage] = React.useState(true)
  const [contactPage, setContactPage] = React.useState(false)
  const [customLandingBrandName, setCustomLandingBrandName] =
    React.useState(null)
  const [landingURL, setLandingURL] = React.useState("/")
  const [darkBg, setDarkBg] = React.useState(false)
  // console.log(customLandingBrandName)

  // Context definition
  const customLandingBrand = React.useContext(CustomLandingBrandContext)

  // Process cookies and customLandingBrandContext and store them in state
  React.useEffect(() => {
    const customLandingBrandContextOrCookie =
      customLandingBrand?.customLandingBrand ||
      windowGlobal?.sessionStorage?.getItem("customLanding")

    if (customLandingBrandContextOrCookie) {
      setCustomLandingBrandName(customLandingBrandContextOrCookie)
      setLandingURL("/thenext" + customLandingBrandContextOrCookie)
    }
  }, [windowGlobal, customLandingBrand])

  // Disable scroll on body if menu is open
  React.useEffect(() => {
    menuShow === true
      ? documentGlobal.body.classList.add("menuShow")
      : documentGlobal.body.classList.remove("menuShow")
  }, [menuShow, documentGlobal])

  // Disable animations on window resize
  React.useEffect(() => {
    const resizeAnimationAddRemoveClass = () => {
      let resizeTimer
      documentGlobal.body.classList.add("resize-animation-stopper")
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        documentGlobal.body.classList.remove("resize-animation-stopper")
      }, 400)
    }

    windowGlobal.addEventListener("resize", resizeAnimationAddRemoveClass)

    return () =>
      windowGlobal.removeEventListener("resize", resizeAnimationAddRemoveClass)
  }, [windowGlobal, documentGlobal])

  // // Apply class on bottom of page
  // React.useEffect(() => {
  //     const bottomPageClassAdder = () => {
  //       if (windowGlobal.innerHeight + windowGlobal.scrollY >= documentGlobal.body.offsetHeight) {
  //         documentGlobal.body.classList.add("endOfPage")
  //       } else {
  //         documentGlobal.body.classList.remove("endOfPage")
  //       }

  //     windowGlobal.addEventListener("scroll", bottomPageClassAdder)

  //     return () => windowGlobal.removeEventListener("scroll", bottomPageClassAdder)
  //   }
  // }, [windowGlobal])

  const transitionTimeout = 250

  // menu Close
  const closeMenu = () => {
    setTimeout(() => setMenuShow(false), transitionTimeout)
  }

  // Set footer special class on contact page
  React.useEffect(() => {
    if (location.pathname.includes("contact")) {
      setTimeout(() => setContactPage(true), transitionTimeout)
    } else {
      setTimeout(() => setContactPage(false), transitionTimeout)
    }
  }, [location])

  // Set dark theme on custom landing page
  React.useEffect(() => {
    if (location.pathname.includes("thenext")) {
      setTimeout(() => setDarkBg(true), transitionTimeout)
    } else {
      setTimeout(() => setDarkBg(false), transitionTimeout)
    }
  }, [location])

  // Resize logo on scroll
  const setLogoSize = e => {
    const scrollPos = e?.target?.scrollingElement?.scrollTop
    scrollPos > 50 ? setTopOfPage(false) : setTopOfPage(true)
  }
  React.useEffect(() => {
    windowGlobal.addEventListener("scroll", setLogoSize)
    return () => windowGlobal.removeEventListener("scroll", setLogoSize)
  })

  // Set main Marquee contents
  const marqueeList = React.useMemo(() => {
    const defaultValue = [
      "The next where.",
      "The next space.",
      "The next experience.",
      "The next product.",
    ]

    if (customLandingBrandName)
      defaultValue.push(`The next ${customLandingBrandName}.`)

    return defaultValue
  }, [customLandingBrandName])

  return (
    <div className={`layout ${darkBg ? "darkBg" : "lightBg"}`}>
      <Menu menuShow={menuShow} closeMenu={closeMenu} />
      <Navbar
        topOfPage={topOfPage}
        menuShow={menuShow}
        setMenuShow={setMenuShow}
        landingURL={landingURL}
        closeMenu={closeMenu}
      />

      <main className={`${location?.action ? "animatePageTransition" : ""}`}>
        <CookieBanner />
        <Marquee list={marqueeList} className="topMarquee" />
        <Transition location={location}>
          <div className="contents">{children}</div>
        </Transition>
      </main>
      <Footer contactPage={contactPage} landingURL={landingURL} />
    </div>
  )
}
