// main imports
import * as React from "react"

// plugin imports

// style imports
import "./menu.scss"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

// svg imports

// components imports

// component
export default function Menu({ menuShow, closeMenu }) {
  const { t, changeLanguage, language } = useI18next()

  const menuItems = [
    { link: "/works", text: t("Trabajos") },
    { link: "/weare", text: t("Somos") },
    // { link: "/method", text: t("Método") },
    { link: "/contact", text: t("Contacto") },
    {
      link: "https://www.instagram.com/venturexperience/",
      text: "Instagram",
      external: true,
    },
    {
      link: "https://www.linkedin.com/company/venturexperience/",
      text: "LinkedIn",
      external: true,
    },
  ]

  return (
    <div id="navMenu" className={`${menuShow ? "menuShow" : ""}`}>
      <div id="menuBg">
        <div className="menuBgLinesContainer">
          {menuItems.map((item, i) => (
            <h1 className="menuBgLine" key={"menuBgLine" + i}>
              &nbsp;
            </h1>
          ))}
          <h1 className="menuBgLine">&nbsp;</h1>
        </div>
      </div>

      {menuItems.map(item =>
        item?.external ? (
          <a
            href={item?.link}
            onClick={closeMenu}
            target="_blank"
            rel="noopener noreferrer"
            className="menuIt"
            key={item?.text}
          >
            <h1>{item?.text}</h1>
          </a>
        ) : (
          <Link
            to={item?.link}
            onClick={closeMenu}
            className="menuIt"
            key={item?.text}
          >
            <h1>{item?.text}</h1>
          </Link>
        )
      )}
      <div className="menuIt languages">
        <h1>
          {language === "en" ? (
            <button
              href="#"
              onClick={e => {
                e.preventDefault()
                changeLanguage("es")
              }}
            >
              <span>ESP</span>
            </button>
          ) : (
            <button
              onClick={e => {
                e.preventDefault()
                changeLanguage("en")
              }}
            >
              <span>ENG</span>
            </button>
          )}
        </h1>
      </div>
    </div>
  )
}
