// main imports
import * as React from "react"

// plugin imports
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

// style imports
import "./CookiesBanner.scss"
import { Trans, useTranslation } from "react-i18next"
// import { Link } from "gatsby-plugin-react-i18next";
import ToggleButton from "./ToggleButton"
import ClientOnly from "./ClientOnly"

// components imports

// svg imports

// function exports for use in other pages
export const addCookies = location => {
  // console.log("addCookies running");
  const oneYear = 365 * 24 * 60 * 60 * 1000 //one year in seconds, to set expiry date for the cookie
  const acceptAppendix = "=true;path=/;SameSite=none;Secure;Max-Age=" + oneYear
  // console.log(acceptAppendix);

  if (typeof document != "undefined") {
    document.cookie = "cookiesStored" + acceptAppendix
    document.cookie = "cookiesAccepted" + acceptAppendix
    document.cookie = "gatsby-gdpr-google-tagmanager" + acceptAppendix

    // console.log("Initializing tracking");
    initializeAndTrack(location)
  }
}
export const rejectCookies = () => {
  // console.log("rejectCookies running");
  const oneYear = 365 * 24 * 60 * 60 * 1000 //one year in seconds, to set expiry date for the cookie
  const rejectAppendix = "=false;path=/;SameSite=none;Secure;Max-Age=" + oneYear

  if (typeof document != "undefined") {
    document.cookie =
      "cookiesStored=true;path=/;SameSite=none;Secure;Max-Age=" + oneYear
    document.cookie = "cookiesAccepted" + rejectAppendix
    document.cookie = "gatsby-gdpr-google-tagmanager" + rejectAppendix
  }
}
export const getCookie = name => {
  if (typeof document != "undefined") {
    // console.log(document);
    var v = document?.cookie?.match("(^|;) ?" + name + "=([^;]*)(;|$)")
    // console.log(v);
    if (v) {
      if (v[2] === "true") {
        return true
      } else if (v[2] === "false") {
        return false
      } else {
        return v[2]
      }
    }
  }
  return null
}
export const resetCookiesPanel = () => {
  // console.log("rejectCookies running")
  const oneYear = 365 * 24 * 60 * 60 * 1000 //one year in seconds, to set expiry date for the cookie
  const rejectAppendix = "=false;path=/;SameSite=none;Secure;Max-Age=" + oneYear

  if (typeof document != "undefined") {
    document.cookie = "cookiesStored" + rejectAppendix
  }
}

// component
export default function CookieBanner() {
  const { t } = useTranslation("cookiesBanner")

  const [funcCookies, setFuncCookies] = React.useState(true)
  const [mktCookies, setMktCookies] = React.useState(
    getCookie("cookiesAccepted")
  )
  const [cookiesStored, setCookiesStored] = React.useState(
    getCookie("cookiesStored")
  )

  const location = useLocation()

  // console.log(cookiesStored);

  // console.log("cookiesStored is: " + cookiesStored);
  // console.log("cookiesAccepted is: " + cookiesAccepted);

  React.useEffect(() => {
    // console.log("useEffect running");

    if (mktCookies && cookiesStored) {
      // if cookies are accepted and have not been previously added, store them
      // console.log("Accepting cookies");
      addCookies(location)
    }

    if (!mktCookies && cookiesStored) {
      // if cookies are disabled, remove them
      // console.log("Rejecting cookies");
      rejectCookies()
    }
  }, [mktCookies, cookiesStored, location])

  return (
    <ClientOnly>
      {!cookiesStored && (
        <div className={`cookiesBanner`}>
          <h2>
            <Trans ns="cookiesBanner">Cookies</Trans>
          </h2>
          <p>
            <Trans ns="cookiesBanner">
              Utilizamos cookies para optimizar tu experiencia en nuestra web.
            </Trans>
          </p>
          <div className="buttonsContainer">
            <ToggleButton
              label={t("Funcionales")}
              className="untoggable"
              checked={funcCookies}
              onClick={() => setFuncCookies(true)}
            />
            <ToggleButton
              label={t("Marketing")}
              checked={mktCookies}
              onClick={() => {
                setMktCookies(!mktCookies)
              }}
            />
          </div>

          <button
            onClick={() => setCookiesStored(true)}
            className="saveConfigButton"
          >
            <p>
              <Trans ns="cookiesBanner">Guardar configuración</Trans>
            </p>
          </button>
          <button
            onClick={() => {
              setCookiesStored(true)
              setMktCookies(true)
            }}
            className="acceptAllButton"
          >
            <h2>
              <Trans ns="cookiesBanner">ACEPTAR TODAS</Trans>
            </h2>
          </button>
        </div>
      )}
    </ClientOnly>
  )
}
