/* eslint-disable jsx-a11y/media-has-caption */

// main imports
import * as React from "react"
import Loader from "./loader"
import Vimeo from "@u-wave/react-vimeo"

// style imports
import "./video.scss"
import { Trans } from "gatsby-plugin-react-i18next"

// components imports

// svg imports

export default function VimeoVideo({
  autoPlay = true,
  loop = true,
  background = false,
  videoId = "",
  id = "",
  className = "",
  fullScreen = false,
}) {
  const videoRef = React.useRef(null)

  const [loaded, setLoaded] = React.useState(false)
  const [volume, setVolume] = React.useState(0)

  return (
    <div
      id={id}
      className={`videoComponent ${loaded ? "videoLoaded" : ""} ${className} ${
        fullScreen ? "noCrop" : ""
      }`}
      role="button"
      tabIndex={0}
    >
      {videoId && (
        <>
          <Loader loaded={loaded} />
          <div className="videoOverlay" />
          <Vimeo
            id={videoId}
            ref={videoRef}
            video={videoId}
            controls={fullScreen}
            className="embedVideo"
            volume={volume}
            muted={autoPlay}
            autoplay={autoPlay}
            loop={loop}
            onPlaying={() => {
              setTimeout(setLoaded(true), 500)
            }}
            playsInline
            dnt={true} // Disables tracking, if active we must block cookies, add them to legal, etc.
          />
          <div className="videoButtons">
            {!background &&
              !fullScreen &&
              (volume === 0 ? (
                <button className="soundIcon" onClick={() => setVolume(1)}>
                  <span>
                    <Trans>Sound</Trans>
                  </span>
                </button>
              ) : (
                <button className="soundIcon" onClick={() => setVolume(0)}>
                  <span>
                    <Trans>Mute</Trans>
                  </span>
                </button>
              ))}
          </div>
        </>
      )}
    </div>
  )
}
