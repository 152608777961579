// main imports
import * as React from "react"

// plugin imports
import AnalogClock from "analog-clock-react"

// style imports
import "./analogClock.scss"

// svg imports
import ClockBg from "../../svg/clockBg.svg"

// components imports

// component

export default function AnalogClockCustom({ timeZone = "UTC" }) {
  const [options, setOptions] = React.useState({
    useCustomTime: true,
  })

  const updateClock = () => {
    let localTime = new Date().toLocaleString("en", { timeZone: timeZone })
    let date = new Date(localTime)

    setOptions({
      ...options,
      seconds: date.getSeconds(),
      minutes: date.getMinutes(),
      hours: date.getHours(),
    })
  }

  React.useEffect(() => {
    updateClock()

    const interval = setInterval(() => {
      updateClock()
    }, 1000)

    return () => clearInterval(interval)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="analogClock">
      <div>
        <ClockBg className="clockBg" />
        <AnalogClock {...options} />
      </div>
      <div id="ampm">{options.hours >= 12 ? "PM" : "AM"}</div>
    </div>
  )
}
